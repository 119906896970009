import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpCommonService } from './http-common.service';

@Injectable({
    providedIn: 'root'
})

export class LeadService {

    constructor(
        private httpCommonService: HttpCommonService) {
    }

    getServiceGridRecords(leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`issues/${leadId}`, inputData);
    }

    getServiceCallId(): Observable<any> {
        return this.httpCommonService.getRecord(`get-call-id`);
    }

    postRequestForm(inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`request`, inputData);
    }

    postServiceForm(leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`calls/${leadId}`, inputData);
    }

    postFollowUpForm(call_id: string, leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`calllines/${call_id}/${leadId}`, inputData);
    }

    updateFollowUpForm(id: number, leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`calllines-update/${id}/${leadId}`, inputData);
    }

    updateServiceForm(call_id: string, leadId: string, inputData: object): Observable<any> {
        return this.httpCommonService.postRecord(`call/update/${call_id}/${leadId}`, inputData);
    }

    getNextCallLineId(call_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`next-callline-id/${call_id}`);
    }

    getServiceFormEdit(call_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`call/${call_id}`);
    }

    getFollowUpFormEdit(call_id: string, callline_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`callline-details/${call_id}/${callline_id}`);
    }

    getServiceGrid(leadId: string, inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`callgrid/${leadId}`, inputData);
    }

    getCallineGrid(call_id: string, inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`calllinegrid/${call_id}`, inputData)
    }

    getLocationDataSource(leadId: string): Observable<any> {
        return this.httpCommonService.getRecord(`lead/affiliate/organization/${leadId}`);
    }

    getPrimaryCompanyId(leadId: string): Observable<any> {
        return this.httpCommonService.getRecord(`lead/organization/${leadId}`);
    }

    getLeadLineList(leadId: string,): Observable<any> {
        return this.httpCommonService.getRecord(`issue-leadline/${leadId}`);
    }

    getCheckCallId(call_id: string): Observable<any> {
        return this.httpCommonService.getRecord(`check-call-id/${call_id}`);
    }

    getLeadFields(): Observable<any> {
        return this.httpCommonService.getRecord(`lead-fields`);
    }

    editlead(lead_id: any): Observable<any> {
        return this.httpCommonService.getRecord(`edit-lead/${lead_id}`);
    }

    getAllContactList(): Observable<any> {
        return this.httpCommonService.getRecord(`get-contact-list`);
    }

    updateLead(lead_id: any, inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`update-lead/${lead_id}`, inputData);
    }

    updateStakeholder(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`update-stakeholder`, inputData);
    }

    updateCompanyDetails(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`update-company-details`, inputData);
    }

    updateOwnershipDetails(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`update-ownership-details`, inputData);
    }

    updateSummaryDetails(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`update-summary-details`, inputData);
    }

    updateStatusDetails(inputData: Object): Observable<any> {
        return this.httpCommonService.postRecord(`update-status-details`, inputData);
    }
}
