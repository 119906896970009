<h1 mat-dialog-title>
    <span>
        Edit Lead Summary
    </span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper summary-form-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #editLeadSummary="ngForm" novalidate autocomplete="off"
        (ngSubmit)="editLeadSummary.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper">
            <div class="lead-summary-wrapper">
                <div class="row">
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Lead ID</div>
                            <kendo-textbox [(ngModel)]="leadSummaryPayload.deal_id" name="deal_id" autocomplete="off"
                                [disabled]="true">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Hubspot Lead ID</div>
                            <kendo-textbox [(ngModel)]="hubspot_id" name="hubspotId" autocomplete="off"
                                [disabled]="true">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Legacy M1 ID</div>
                            <kendo-textbox [(ngModel)]="legacy_id" name="legacy_id" autocomplete="off"
                                [disabled]="true">
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Project Name <sup>*</sup></div>
                            <kendo-textbox [(ngModel)]="leadSummaryPayload.dealname" name="dealname" autocomplete="off"
                                required>
                            </kendo-textbox>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-field mb-3">
                            <div class="label-text">Project Details <sup>*</sup></div>
                            <kendo-textarea [(ngModel)]="leadSummaryPayload.long_description" name="long_description"
                                [rows]="5" autocomplete="off" resizable="vertical" required>
                            </kendo-textarea>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="projectShippingDataList.length > 0 else projectShippingAddress">
                        <div class="form-field mb-3">
                            <div class="label-text">Project Shipping Address <sup>*</sup></div>
                            <kendo-dropdownlist [data]="projectShippingDataList" [textField]="'value'"
                                [valueField]="'key'" [valuePrimitive]="true" [loading]="isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.KeyValue"
                                [(ngModel)]="leadSummaryPayload.shipping_address_id" name="project_shipping_address"
                                placeholder="Select" required>
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <ng-template #projectShippingAddress>
                        <div class="col-12">
                            <div class="form-field mb-3">
                                <div class="label-text">Project Shipping Address</div>
                                <kendo-dropdownlist [data]="projectShippingDataList" [textField]="'value'"
                                    [valueField]="'key'" [valuePrimitive]="true" [loading]="isFormLoading"
                                    [defaultItem]="commonSettings.defaultItems.KeyValue"
                                    [(ngModel)]="leadSummaryPayload.shipping_address_id" name="project_shipping_address"
                                    placeholder="Select">
                                </kendo-dropdownlist>
                            </div>
                        </div>
                    </ng-template>
                    <div class="col-6">
                        <div class="form-field mb-3">
                            <div class="label-text">Business Development</div>
                            <kendo-dropdownlist [data]="businessDevelopmentDataList" [textField]="'key'"
                                [valueField]="'value'" [valuePrimitive]="true" [loading]="isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.fieldsValueKey"
                                [(ngModel)]="leadSummaryPayload.business_development" name="business_development"
                                placeholder="Select">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Back
            </button>
            <button mat-flat-button type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>